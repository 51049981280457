export default class Country {
  name?: string
  code?: string
  status?: number
  len?: Array<number>

  constructor(init?: Partial<Country>) {
    Object.assign(this, init)
  }

  public get cnName(): string {
    switch (this.code) {
      case '+86':
        return '中国大陆'
      case '+886':
        return '中国台湾'
      case '+852':
        return '中国香港'
      case '+853':
        return '中国澳门'
      case '+63':
        return '菲律宾'
      case '+66':
        return '泰国'
      case '+855':
        return '柬埔寨'
      case '+971':
        return '迪拜'
      case '+60':
        return '马来西亚'
      default:
        return ''
    }
  }
}
