import http from '~/utils/http'


export class ConfigApi {
  // @POST('/jav/config/dict')
  // Future<List<Map>> dict(@Body() DictParam param);
  static dict(params: DictParam, opt?: HttpOptions) {
    return http.post<Array<Record<string, any>>>({
      ...opt,
      url: '/jav/config/dict',
      body: params
    })
  }

  // @POST('/jav/config/position')
  // Future<Position> position(@Body() DictParam param);
  static position(params: DictParam, opt?: HttpOptions) {
    return http.post<Position>({
      ...opt,
      url: '/jav/config/position',
      body: params
    })
  }

  // @POST('/jav/config/startPage')
  // Future<Splash> startPage();
  static startPage(opt?: HttpOptions) {
    return http.post<Splash>({
      ...opt,
      url: '/jav/config/startPage'
    })
  }

  // @POST('/jav/config/version')
  // Future<AppVersion> version(@Body() AppVersionParam param);
  static version(params: AppVersionParam, opt?: HttpOptions) {
    return http.post<AppVersion>({
      ...opt,
      url: '/jav/config/version',
      body: params
    })
  }
}
